
import moment from 'moment'
import { defineComponent, ref, computed, reactive, watch, onMounted } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import UploadFiles from '@/components/common/UploadFiles.vue'
import { useRoute, useRouter } from 'vue-router'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import ticketRepositories from '@/repositories/ticketRepositories'
import { useNotification } from '@/composables'
import {
  formatTime,
  shortcutsDatetimePicker,
  formatShortDateMonthTime,
} from '@/utils/dateTime'

import {
  getOrderStatusColor,
  getAddressStatusColor,
  getTrackingOfOrder,
  validateOrderAction,
  getWarningContent,
} from '@/utils/orderUtils'
import {
  statusTitleMap,
  statusListSeller,
  addressTitleMap,
  ADDRESS_STATUS,
  ORDER_STATUS,
  requestDesignServiceTitleMap,
  HIRE_DESIGN_STATUS,
  RequestSellerStatusEnum,
  RequestSeller,
} from '@/utils/constants'
import FilterTable from '@/components/common/FilterTable.vue'
import Pagination from '@/components/common/Pagination.vue'
import {
  ArrowDown,
  Warning,
  InfoFilled,
  CopyDocument,
  Message,
} from '@element-plus/icons'
// import SlotError from '@/components/common/SlotError.vue'
import OrderImagePreview from './components/OrderImagePreview.vue'
import { formatterDollars } from '@/utils/formatter'
import { ElMessageBox, ElDialog } from 'element-plus'
import DepositDialog from '../balance/DepositDialog.vue'
import axios from '@/services/axios'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import { downloadFile, getFileNameFromUrl } from '@/utils/file'
import { validateEditArtworkCheck } from '@/utils/orderUtils'
import { copyToClipboardV2 } from '@/utils/string'
import { useResource } from '@/services'
import Editor from '@/pages/admin/catalog/components/Editor.vue'
import OrderDataService from '@/services/orderProductService'
import userSettingsRepositories from '@/repositories/userSettingsRepositories'
import { UserTypes } from '@/utils/types'
import CustomDialog from '@/components/orders/CustomDialog.vue'
import { useFetchData } from '@/composables'
import type { FormInstance, FormRules } from 'element-plus'
import useShopRepositories from '@/repositories/useShopRepositories'
import RequestSellerDialog from '@/pages/agency/orders/components/RequestSellerDialog.vue'

interface FileItem {
  uid: string
  name?: string
  status?: string
  response?: string
  url?: string
  preview?: string
  originFileObj?: any
  file: string | Blob
  raw: string | Blob
}

export default defineComponent({
  components: {
    sectionLayoutContent,
    UploadFiles,
    FilterTable,
    Editor,
    Pagination,
    ArrowDown,
    Message,
    OrderImagePreview,
    DepositDialog,
    Warning,
    InfoFilled,
    CopyDocument,
    CustomDialog,
    RequestSellerDialog,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()
    const { success, error, warning } = useNotification()
    let importResultsDialogVisible = ref(false)
    let isShowModalImport = ref<boolean>(false)
    let isShowTicketListModal = ref(false)
    let isShowTicketDetailModal = ref(false)
    let isDisableAddContent = ref(true)
    let isAddTicketModal = ref(false)
    let selectdOrderTicketId = ref()
    let selectdOrderTicketName = ref()
    const isRead = ref('unread')
    const breadcrumbs = ['Orders', '']
    const titleImport = 'Import external orders'
    const titleExport = 'Export Orders'
    const titleOtherButton1 = 'Data Catalog'
    const titleReport = 'Report Orders'

    const iconImport = 'fas fa-cloud-download-alt'
    const iconExport = 'fas fa-file-excel'
    const iconOtherButton1 = 'fas fa-database'
    const iconReport = 'fas fa-chart-pie'

    const isDisplayCountOrderByStatus = ref(false)

    const content = computed(() => {
      return {
        breadcrumbs,
        titleImport,
        titleExport,
        titleOtherButton1,
        titleReport,
        iconImport,
        iconExport,
        iconOtherButton1,
        iconReport,
      }
    })

    const sellerTeam = ref()
    const info = JSON.parse(localStorage.getItem('info') || '')
    const getSeller = async () => {
      const resourceSellerTeam = '/seller-teams'
      const { get } = useResource(resourceSellerTeam)
      const { data, status } = await get(info.sellerTeam.id)
      if (status === 200) {
        sellerTeam.value = data as any
        // console.log("🚀 ~ file: List.vue:1519 ~ getSeller ~ sellerTeam.value", sellerTeam.value)
      }
    }
    getSeller()

    const subCategory = computed(() => {
      return category.find((s: any) => s.title === ticket.form.category)
        ?.children
    })
    let userInfo = undefined
    try {
      userInfo = JSON.parse(localStorage?.getItem('info') || '')
    } catch (e) {
      return error("Can't find user info")
    }

    const onShowAllTicketsForOrder = async (order: any) => {
      selectdOrderTicketId.value = order._id
      selectdOrderTicketName.value = order.orderName
      ticket.form.orders = order._id
      ticketParams.orders = order._id
      await fetchData()
      await countTicket({ orders: order._id })
      ticketParams.is_read = 'all'
      isShowTicketListModal.value = true
      isShowTicketDetailModal.value = false
    }

    const backToTicketList = async () => {
      dataTicket.isLoading = true
      await fetchData()
      dataTicket.isLoading = false
      isShowTicketDetailModal.value = false
    }

    const onShowCreaticketModal = () => {
      ticket.form.subject = `ORDER ${selectdOrderTicketName.value}`
      ticket.form.orders = selectdOrderTicketId.value
      ticket.form.status = 2
      isAddTicketModal.value = true
    }

    const onCloseCreaticketModal = () => {
      isAddTicketModal.value = false
    }

    const onGoToImportHistory = () => {
      //
    }

    const { editOrder } = useOrderRepositories()

    const onClickWarningLineShip = async (order: any) => {
      if (order && order.items?.length > 0) {
        order.items.forEach((item: { shippingTitle: string }) => {
          const shippingTitleItem = item?.shippingTitle?.trim()
          if (
            shippingTitleItem !== '' &&
            shippingTitleItem.toLowerCase() !== 'standard'
          ) {
            item.shippingTitle = 'standard'
          }
        })
      }
      const { status } = await editOrder(order._id, order)
      if (status === 200) {
        success('Change success')
      } else {
        error('Failed')
      }
    }

    const detectLineShipOfOrder = (order: any) => {
      if (
        order &&
        order.items?.length > 0 &&
        validateEditArtworkCheck(order.status)
      ) {
        const even = (item: any) => {
          const lineShip = item.shippingTitle?.trim()
          return (
            lineShip && lineShip !== '' && lineShip.toLowerCase() !== 'standard'
          )
        }
        const check = order.items.some(even)
        return check
      }
    }

    const fileList = ref<FileItem[]>([])
    const attacmentList = ref<FileItem[]>([])
    const data = reactive({
      isLoading: false,
      isLoadingExport: false,
      notificationOrder: ref<any>([]),
      daterange: '',
      resImport: {
        successNumber: null,
        failedNumber: null,
      },
      isLoadingCount: false,
      stores: ref<any[]>([]),
    })

    const dataTicket = reactive({
      isLoading: false,
      isLoading2: false,
      ticket: {
        _id: '',
        description: '',
        created_at: null,
        subject: '',
        requester: '',
        body_text: '',
        attachments: [],
        status: null,
        conversations: ref<any>([]),
      },
    })

    const balance = ref('0')
    const loadBalance = async () => {
      try {
        let res = await axios.get('/balances/seller-teams')
        if (res.data) {
          balance.value = res.data.amount || '0'
        }
      } catch (err) {
        balance.value = '0'
      }
    }

    loadBalance()

    const depositDialog = ref<InstanceType<typeof DepositDialog>>()
    const openDepositDialog = (amount: any) => {
      depositDialog.value?.toggle('create', null, amount)
    }

    const changeBalance = async () => {
      loadBalance()
    }

    const { getContactSetting } = userSettingsRepositories()
    const contactUrl = ref<any>()
    const getContactSettings = async () => {
      contactUrl.value = (await getContactSetting()) as any
    }

    const onClickContactSupport = async () => {
      if (!contactUrl.value) {
        await getContactSettings()
      }
      window.open(contactUrl.value)
    }

    const { push } = useRouter()

    const {
      getOrders,
      state,
      importCsv,
      onImportCsv,
      submitOrder,
      exportOrder,
      cancelOrders,
      verifyAddressOrders,
      holdOrders,
      unHoldOrders,
      getNotificationOrder,
      syncedTrackToStore,
      hireOrderDesigns,
      reportOrder,
    } = useOrderRepositories()

    const params = reactive<any>({
      limit: route.query.limit || 10,
      page: route.query.page || 1,
      search: route.query.search || null,
      status: route.query.status || null,
      source: route.query.source || null,
      addressValid: route.query.addressValid || null,
      designStatus: route.query.designStatus || null,
      syncedTrackToStore: route.query.syncedTrackToStore || null,
      storeName: route.query.storeName || null,
      orderDate: {
        startDate: route.query.startDate || null,
        endDate: route.query.endDate || null,
        timezone: route.query.timezone || null,
      },
      fulfillmentWarning: route.query.fulfillmentWarning || null,
      requestSellerReasonCode: route.query.requestSellerReasonCode || null,
    })

    const ticketParams = reactive<any>({
      limit: 10,
      page: 1,
      search: null,
      is_read: 'all',
      orders: null,
    })

    const {
      documents: tickets,
      isLoading: loading,
      fetchData,
      total,
    } = useFetchData('orders/ticket/all', ticketParams)

    watch(ticketParams, fetchData)

    const countTotalTicket = ref(0)

    const countTicket = async (params: any) => {
      const res = (await axios.get<any>(`orders/ticket/count`, {
        params,
      })) as any
      if (res.status === 200) {
        countTotalTicket.value = res.data
      } else {
        error('Opps...Unexpected error. Please try again latter!')
      }
    }

    const ticket = reactive({
      form: {
        status: 2,
        description: '',
        orders: '',
        subject: '',
        category: '',
        subcategory: '',
        prefer_solution: '',
        product_type: '',
        custom_fields: {},
      },
    })
    const preferSolution = reactive([
      {
        key: 'Check current order status',
        value: 'Check current order status',
      },
      { key: 'Replace', value: 'Replace' },
      { key: 'Refund', value: 'Refund' },
    ])
    const category = reactive([
      {
        title: 'Shipping Issue',
        tooltip: '',
        children: [
          {
            title: 'Tracking Infor is not updated',
            tooltip: 'tracking ko thấy update',
          },
          {
            title: 'The next node in Tracking Infor is not updated',
            tooltip: 'tracking update chậm, mãi chưa thấy nổi node tiếp theo',
          },
          {
            title:
              "Tracking Infor is Delivered but buyer hasn't received package",
            tooltip: 'tracking báo đã giao thành công nhưng buyer chưa nhận đc',
          },
          {
            title: 'Order has been returned',
            tooltip: 'đơn hàng bị hoàn trả',
          },
          {
            title: 'Order has been lost',
            tooltip: 'đơn hàng bị thất lạc',
          },
          {
            title: 'Order has been forwarded',
            tooltip: 'đơn hàng bị chuyển tiếp',
          },
          {
            title: 'Order has been damaged during shipment progress',
            tooltip: 'đơn hàng hư hại trong quá trình vận chuyển',
          },
          {
            title: 'Tax issue',
            tooltip: 'các vấn đề liên quan đến thuế quan',
          },
          {
            title: 'Other',
            tooltip: 'các vấn đề khác',
          },
        ],
      },
      {
        title: 'Printing Issue',
        tooltip: '',
        children: [
          {
            title: 'Blurred print',
            tooltip: 'in mờ, in bị lem mực, loang màu',
          },
          {
            title: 'Color issue',
            tooltip: 'khác màu',
          },
          {
            title: 'Wrong print',
            tooltip: 'in sai design, in lẫn với design khác, in lệch...',
          },
          {
            title: 'Missing print',
            tooltip: 'in thiếu chi tiết, in mất chữ',
          },
          {
            title: 'Other',
            tooltip: '',
          },
        ],
      },
      {
        title: 'Product Issue',
        tooltip: '',
        children: [
          {
            title: 'Broken',
            tooltip: 'vỡ, gãy, rách, nhăn, gập, sản phẩm không hoạt động',
          },
          {
            title: 'Scratched',
            tooltip: 'xước, bong tróc,...',
          },
          {
            title: 'Stained',
            tooltip: 'bẩn',
          },
          {
            title: 'Missing product',
            tooltip: 'thiếu sản phẩm, gửi sai hàng',
          },
          {
            title: 'Wrong Size',
            tooltip: 'nhầm size, size chênh lệch so với size chart,..',
          },
          {
            title: 'Other',
            tooltip: '',
          },
        ],
      },
      {
        title: 'Other',
        tooltip: '',
        children: [],
      },
    ])
    const stateImport = reactive({
      createProduct: false,
    })

    const stateTickets = reactive({
      data: [],
      total: 0,
    })

    const onFilterCallback = (filter: any) => {
      params.search = filter.search
    }

    const handleSelectionChange = (val: any) => {
      state.multipleSelection = val
    }

    const columns = [
      {
        key: 'name',
        title: 'Order',
        dataIndex: 'orderName',
        slots: { title: 'customTitle', customRender: 'orderName' },
        minWidth: '15',
      },
      {
        title: 'Customer',
        dataIndex: 'fullName',
        minWidth: '10',
      },
      // {
      //   title: 'Created',
      //   dataIndex: 'createdDate',
      //   minWidth: '10'
      // },
      {
        title: 'FFM status',
        dataIndex: 'status',
        minWidth: '10',
        align: 'center',
      },
      {
        title: 'Source',
        dataIndex: 'source',
        minWidth: '8',
        align: 'center',
      },
      {
        title: 'Address',
        dataIndex: 'addressValid',
        minWidth: '10',
        align: 'center',
      },
      // {
      //   title: 'Invoice',
      //   dataIndex: 'invoice',
      //   minWidth: '10',
      //   align: 'center'
      // },
      // {
      //   title: 'Personalize',
      //   dataIndex: 'personalized',
      // },
      {
        title: 'Artwork',
        dataIndex: 'designStatus',
        minWidth: '8',
        align: 'center',
      },
      // {
      //   title: 'Export Status',
      //   dataIndex: 'exportStatus',
      // },
      // {
      //   title: 'Shipping title',
      //   dataIndex: 'shippingTitle',
      //   minWidth: '10',
      //   align: 'center'
      // },
      {
        title: 'FFM Cost',
        dataIndex: 'sellerAmount',
        minWidth: '10',
        align: 'center',
      },
      {
        title: 'Tracking',
        dataIndex: 'trackingNumber',
        minWidth: '10',
        align: 'center',
      },
      // {
      //   title: 'Store',
      //   dataIndex: 'storeName',
      //   minWidth: '10',
      //   align: 'center',
      // },
      {
        title: 'Actions',
        dataIndex: 'actions',
        minWidth: '7',
        align: 'center',
      },
    ]

    const showModalImport = () => {
      isShowModalImport.value = true
    }

    const onSubmitImport = async () => {
      if (!fileList.value || fileList.value.length === 0) {
        warning('Please import your file!')
        return
      }
      let file = fileList.value[0].raw as any
      if (!file.name.endsWith('.csv')) {
        error('Wrong file! Please import csv file.')
        return
      }

      try {
        data.isLoading = true
        const res = (await importCsv(file, stateImport.createProduct)) as any
        if (res?.status === 200 || res?.status === 201) {
          success('Import success!')
        } else {
          error(res.data.message)
        }
        fileList.value = []
        isShowModalImport.value = false

        // hien thi thong bao ket qua import
        data.resImport = res.data
        importResultsDialogVisible.value = true

        // load lai danh sach order
        getOrders(params)
      } catch (error) {
        ElMessage.error(
          'Error when import. See order import log to more detail.'
        )
      }
      data.isLoading = false
    }

    const { getAllStores } = useShopRepositories()
    const getAllStoresSellers = async () => {
      let stores = await getAllStores()
      data.stores = stores as any[]
    }

    onMounted(async () => {
      data.isLoading = true
      await getOrders(params)
      await countTicketOrders()
      getAllStoresSellers()
      data.isLoading = false
      let notificationContent = await getNotificationOrder()
      data.notificationOrder = notificationContent.data
    })

    const countTicketOrders = async () => {
      const { search } = ticketRepositories()
      let arrOrderId = []
      if (state.orders && state.orders.length > 0) {
        arrOrderId = state.orders?.map((s: any) => s._id)
      }
      if (arrOrderId.length > 0) {
        const res = await search(arrOrderId)
        if (res && res?.data.length > 0) {
          state.orders = mergeById(state.orders, res.data)
        }
      }
    }

    const mergeById = (a1: any[], a2: any[]) => {
      return a1.map(itm => ({
        ...a2.find(item => item._id === itm._id && item),
        ...itm,
      }))
    }

    const onChangeFiles = (files: any) => {
      fileList.value = files
    }
    const onChangeAttachmentUpload = (files: any) => {
      attacmentList.value = files
    }

    const { getters } = useStore()
    let config = computed(() => {
      return getters['app/appConfig']
    })

    const onDownloadTemplateOrder = () => {
      const url = config.value.templateFile.importOrderFilePath
      downloadFile(url)
    }

    const handleSizeChange = (val: any) => {
      console.log(`${val} items per page`)
    }

    const changeFilter = () => {
      let currentPage = params.page
      let query = { ...route.query, page: currentPage + 1 }
      router.push({ query: query })
    }

    const handleCurrentChange = async (val: any) => {
      console.log(`current page: ${val}`)
    }

    const onChangeDate = async () => {
      if (data.daterange?.length > 1) {
        let startDate = moment(data.daterange[0]).format('YYYY-MM-DD')
        let endDate = moment(data.daterange[1]).format('YYYY-MM-DD')
        params.orderDate = {
          startDate: startDate,
          endDate: endDate,
          timezone: 'America/Los_Angeles',
        }
      } else {
        delete params.orderDate
      }
    }

    const confirmExportOrder = async () => {
      ElMessageBox.confirm(
        `Confirm to export ${state.total} orders?`,
        'Warning',
        {
          confirmButtonText: 'Export',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(async () => {
        await exportDataOrders()
      })
    }

    const reportOrders = async () => {
      ElMessageBox.confirm(
        `Confirm to report ${state.total} orders?`,
        'Warning',
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(async () => {
        data.isLoadingExport = true
        const res = (await reportOrder(params)) as any
        data.isLoadingExport = false
        window.open(res?.data?.url, '_blank')
        success(res?.data?.message || `Export order success`)
      })
    }

    const viewCatalogData = async () => {
      const urlData = config.value.templateFile.dataCatalogFile
      // console.log('urlData --> ', urlData)
      if (urlData) {
        window.open(urlData)
      } else {
        warning('Not found URL')
      }
    }

    const exportDataOrders = async () => {
      try {
        data.isLoadingExport = true
        const res = (await exportOrder(params)) as any
        data.isLoadingExport = false
        window.open(res?.data?.url, '_blank')
        success(res?.data?.message || `Export order success`)
      } catch (e) {
        error()
      }
    }

    const onSubmitOrder = async () => {
      try {
        const res = (await submitOrder({
          ids: state.multipleSelection.map((item: any) => item._id),
        })) as any
        state.multipleSelection = []
        onLoadOrders()
        success(res?.data?.message || ``)
      } catch (e) {
        error()
        //
      }
      //
    }

    const {
      validateHold,
      validateUnHold,
      validateSellerCancel,
      validateVerifyAddress,
      validateOrderActionSeller,
      ALLOW_STATUS_WITH_ACTION,
      validateUpdateSyncedTrackToStore,
    } = validateOrderAction()

    const checkSelectOrderForAction = (action: string) => {
      let filterSelected = []
      if (action === 'hold') {
        filterSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => validateHold(item.status))
      } else if (action === 'unhold') {
        filterSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => validateUnHold(item.status))
      } else if (action === 'cancel') {
        filterSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => validateSellerCancel(item))
      } else if (action === 'verify-address') {
        filterSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(
            item =>
              validateVerifyAddress(item.status) &&
              item.addressValid === ADDRESS_STATUS.US_INVALID
          )
      } else if (action === 'synced-track-to-store') {
        filterSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(
            item =>
              validateUpdateSyncedTrackToStore(item.status) &&
              item?.syncedTrackToStore === false
          )
      } else if (action === 'need-hire-design') {
        filterSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(
            item =>
              !item.isDropship &&
              item.status === ORDER_STATUS.UNFULFILLED &&
              !item.metadata
          )
      }
      return filterSelected?.length > 0
    }

    const holdOrder = async (ids: any[]) => {
      try {
        const res = (await holdOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Hold order success!`)
          onLoadOrders()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const unHoldOrder = async (ids: any[]) => {
      try {
        const res = (await unHoldOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Unhold order success!`)
          onLoadOrders()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const cancelOrder = async (ids: any[]) => {
      try {
        const res = (await cancelOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Cancel order success!`)
          onLoadOrders()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const verifyAddressOrder = async (ids: any[]) => {
      try {
        const res = (await verifyAddressOrders({
          ids: ids,
        })) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Verify Address order success!`)
          onLoadOrders()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const syncedTrackToStoreOrder = async (ids: any[]) => {
      try {
        const res = (await syncedTrackToStore(ids)) as any
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Update order success!`)
          onLoadOrders()
        } else {
          error(res?.data?.message)
        }
      } catch (err) {
        error()
      }
    }

    const onSyncedTrackToStoreSelectedOrders = async () => {
      try {
        const ordersSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => validateUpdateSyncedTrackToStore(item.status))
        if (ordersSelected?.length === 0) {
          warning('No orders to update.')
          return
        }
        const ids = state.multipleSelection.map((item: any) => item._id)
        const res = (await syncedTrackToStore(ids)) as any
        if (res.status === 201 || res.status === 200) {
          state.multipleSelection = []
          onLoadOrders()
          success(`Success`)
        } else {
          error(res.data.message)
        }
      } catch (e) {
        error()
      }
    }

    const onHireDesigns = async () => {
      try {
        const ordersSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(
            item => !item.isDropship && item.status === ORDER_STATUS.UNFULFILLED
          )

        if (ordersSelected?.length === 0) {
          warning('No pending orders for design services.')
          return
        }
        //kiem tra trang thai da thue thiet ke

        const existsMetadata = validateExistsUsingDesignService(ordersSelected)
        if (existsMetadata.length > 0) {
          existsMetadata.forEach((order: any) => {
            warning(`Order ${order.orderName} has utilized design services`)
          })
          return
        }
        //kiem tra trang thai order co phai la drop ship ko

        const podCheck = validateIsNotDropshipOrder(ordersSelected)
        if (podCheck.length > 0) {
          podCheck.forEach((order: any) => {
            warning(`Order ${order.orderName} is a dropship order`)
          })
          return
        }

        //kiem tra catalog code
        const orderNotEnoughCatalogCode =
          validateNoCatalogcodeInOrder(ordersSelected)
        if (orderNotEnoughCatalogCode.length > 0) {
          orderNotEnoughCatalogCode.forEach((order: any) => {
            warning(
              `There is no catalog code mapping for Order ${order.orderName}`
            )
          })
          return
        }

        const res = (await hireOrderDesigns({
          ids: state.multipleSelection.map((item: any) => item._id),
        })) as any
        if (res.status === 201 || res.status === 200) {
          state.multipleSelection = []
          onLoadOrders()
          success(`Success`)
        } else {
          error(res.data.message)
        }
      } catch (e) {
        error()
      }
    }

    const validateSellerHireDesign = async (item: any) => {
      return !item.isDropship && item.status === ORDER_STATUS.UNFULFILLED
    }

    const validateExistsUsingDesignService = (orders: any[]) => {
      return orders.filter((order: any) => {
        return order.metadata
      })
    }

    const validateIsNotDropshipOrder = (orders: any[]) => {
      return orders.filter((order: any) => {
        return order.isDropship
      })
    }

    const validateNoCatalogcodeInOrder = (orders: any[]) => {
      let orderNotEnoughCatalogCode: any[] = []

      orders.forEach((order: any) => {
        const items = order.items.filter((item: any) => {
          return !item.catalogCode
        })
        if (items && items.length > 0) {
          orderNotEnoughCatalogCode.push(order)
        }
      })
      return orderNotEnoughCatalogCode
    }

    const onHoldSelectedOrders = async () => {
      try {
        const ordersSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => validateHold(item.status))
        if (ordersSelected?.length === 0) {
          warning('No orders to update.')
          return
        }
        const res = (await holdOrders({
          ids: state.multipleSelection.map((item: any) => item._id),
        })) as any
        if (res.status === 201 || res.status === 200) {
          state.multipleSelection = []
          onLoadOrders()
          success(`Success`)
        } else {
          error(res.data.message)
        }
      } catch (e) {
        error()
      }
    }

    const onVerifyAddressSelectedOrders = async () => {
      try {
        const ordersSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(
            item =>
              item.addressValid === 0 && validateVerifyAddress(item.status)
          )
        if (ordersSelected?.length === 0) {
          warning('No orders to update.')
          return
        }
        const res = (await verifyAddressOrders({
          ids: state.multipleSelection.map((item: any) => item._id),
        })) as any
        state.multipleSelection = []
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Success`)
          onLoadOrders()
        } else {
          error(res?.data?.message)
        }
      } catch (e) {
        error()
      }
    }

    const onCancelSelectedOrders = async () => {
      try {
        const ordersSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => validateSellerCancel(item))
        if (ordersSelected?.length === 0) {
          warning('No orders to update.')
          return
        }
        const res = (await cancelOrders({
          ids: state.multipleSelection.map((item: any) => item._id),
        })) as any
        state.multipleSelection = []
        if (res?.status === 200 || res?.status === 201) {
          success(res?.data?.message || `Success`)
          onLoadOrders()
        } else {
          error(res?.data?.message)
        }
      } catch (e) {
        error()
      }
    }

    const onUnHoldSelectedOrders = async () => {
      try {
        const ordersSelected = state.multipleSelection
          .map((item: any) => item)
          .filter(item => item.status === 'Hold')
        if (ordersSelected?.length === 0) {
          warning('No orders to update.')
          return
        }
        const res = (await unHoldOrders({
          ids: state.multipleSelection.map((item: any) => item._id),
        })) as any
        if (res.status === 201 || res.status === 200) {
          state.multipleSelection = []
          onLoadOrders()
          success(`Success`)
        } else {
          error(res.data.message)
        }
      } catch (e) {
        error('Unknow error')
        //
      }
    }

    const updateURL = () => {
      router.push({
        query: {
          ...params,
          startDate: params.orderDate.startDate || null,
          endDate: params.orderDate.endDate || null,
          timezone: params.orderDate.timezone || null,
        },
      })
    }

    const onLoadOrders = async () => {
      data.isLoading = true
      await getOrders(params)
      await countTicketOrders()
      data.isLoading = false
    }

    watch(params, () => {
      updateURL()
      onLoadOrders()
    })

    const countOrderByStatus = ref<any>()
    const getCountOrderByStatus = async (): Promise<void> => {
      try {
        data.isLoadingCount = true
        const paramsCount = { limit: 10, page: 1, search: '' }
        const res = await OrderDataService.countOrderByStatus(paramsCount)
        countOrderByStatus.value =
          res.status === 200
            ? res.data.reduce(
                (o: any, c: any) => ({ ...o, [c._id]: c.count }),
                {}
              )
            : {}
      } catch (e) {
        error('')
      }
      data.isLoadingCount = false
    }

    const onChangeDisplayCount = async () => {
      if (!countOrderByStatus.value) {
        await getCountOrderByStatus()
      }
      isDisplayCountOrderByStatus.value = !isDisplayCountOrderByStatus.value
    }

    const onOpenMessageWarningAction = async (action: string) => {
      let statusList = ''
      let actionName = ''
      if (action === 'hold') {
        statusList = ALLOW_STATUS_WITH_ACTION.SELLER_HOLD
        actionName = 'Hold Orders'
      } else if (action === 'unhold') {
        statusList = ALLOW_STATUS_WITH_ACTION.SELLER_UNHOLD
        actionName = 'UnHold Orders'
      } else if (action === 'cancel') {
        statusList = ALLOW_STATUS_WITH_ACTION.SELLER_CANCEL
        actionName = 'Cancel Orders'
      } else if (action === 'verify-address') {
        statusList = ALLOW_STATUS_WITH_ACTION.SELLER_VERIFY_ADDRESS
        actionName = 'Verify address'
      } else if (action === 'synced-track-to-store') {
        statusList = ALLOW_STATUS_WITH_ACTION.SELLER_SYNCED_TRACK_TO_STORE
        actionName = 'Tracked Orders'
      } else if (action === 'need-hire-design') {
        statusList = ALLOW_STATUS_WITH_ACTION.SELLER_HIRE_DESIGN
        actionName = 'Using design service'
      }
      ElMessageBox.confirm(`${actionName}`, {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'primary',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          if (action === 'hold') {
            onHoldSelectedOrders()
          } else if (action === 'unhold') {
            onUnHoldSelectedOrders()
          } else if (action === 'cancel') {
            onCancelSelectedOrders()
          } else if (action === 'verify-address') {
            onVerifyAddressSelectedOrders()
          } else if (action === 'synced-track-to-store') {
            onSyncedTrackToStoreSelectedOrders()
          } else if (action === 'need-hire-design') {
            onHireDesigns()
          }
        })
        .catch(() => {
          // ElMessage({
          //   type: 'info',
          //   message: 'Update canceled',
          // })
        })
    }

    const customDialog = ref<InstanceType<typeof CustomDialog>>()
    const onViewDetailCustom = (custom: any) => {
      customDialog.value?.toggle(custom)
    }
    const formSize = ref('default')
    const ruleFormRef = ref<FormInstance>()

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid: any) => {
        if (valid) {
          const { createTicket, onUploadAttacments } = ticketRepositories()
          const ticketForm = {
            subject: ticket.form.subject,
            description: ticket.form.description,
            description_text: ticket.form.description,
            orders: ticket.form.orders,
            status: ticket.form.status,
            attachments: [] as string[],
            custom_fields: {
              cf_category: ticket.form.category,
              cf_subcategory: ticket.form.subcategory,
              cf_prefered_solution: ticket.form.prefer_solution,
              cf_product_type: ticket.form.product_type,
            },
          }
          if (attacmentList.value && attacmentList.value.length > 0) {
            let file = attacmentList.value[0].raw as any
            const resFile = (await onUploadAttacments(file)) as any
            if (resFile?.status === 200 || resFile?.status === 201) {
              ticketForm.attachments.push(resFile.data.data)
            } else {
              error(resFile.data.message)
            }
            attacmentList.value = []
          }

          const res = await createTicket(ticketForm)
          isAddTicketModal.value = false
          await fetchData()
        } else {
          console.log('error submit!')
        }
      })
    }

    const markTicketResolve = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid: any) => {
        if (valid) {
          dataTicket.isLoading2 = true
          const { addComment, updateTicket } = ticketRepositories()

          const formConversationData = {
            ticket: dataTicket.ticket._id,
            body: 'Seller has marked ticket as resolved',
          }
          await addComment(formConversationData)

          const ticketForm = {
            _id: dataTicket.ticket._id,
            status: 4,
          }
          await updateTicket(ticketForm)
          isShowTicketDetailModal.value = false
          await fetchTicket(dataTicket.ticket._id)
          dataTicket.isLoading2 = false
          formEl.resetFields()
        } else {
          console.log('error submit!')
        }
      })
    }

    const resetForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return
      formEl.resetFields()
    }
    const addComment = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      const { addComment } = ticketRepositories()
      const formData = {
        ticket: dataTicket.ticket._id,
        body: dataTicket.ticket.body_text,
      }
      dataTicket.isLoading = true
      await addComment(formData)
      await fetchTicket(dataTicket.ticket._id)
      dataTicket.isLoading = false
      dataTicket.ticket.body_text = ''
    }

    const selectedTicket = async (item: any) => {
      dataTicket.isLoading = true
      isShowTicketDetailModal.value = true
      await fetchTicket(item._id)
      dataTicket.isLoading = false
      await readTicket(item._id)
    }

    const fetchTicket = async (ticketId: string) => {
      const { getEntity } = ticketRepositories()
      const res = await getEntity(ticketId)

      if (res && res.data) {
        const initialConversation = [
          {
            body: res.data.description,
            is_seller: true,
            full_name: res.data.requester_id.fullName,
            updated_at: res.data.updated_date,
            email: res.data.requester_id.email,
          },
        ]

        dataTicket.ticket._id = res?.data?._id
        dataTicket.ticket.status = res.data.status
        dataTicket.ticket.subject = res?.data?.subject
        dataTicket.ticket.attachments = res?.data?.attachments
        dataTicket.ticket.requester = res.data.requester_id.fullName
          ? res.data.requester_id.fullName
          : res.data.requester_id.email
        dataTicket.ticket.conversations = [
          ...initialConversation,
          ...res?.data?.conversations,
        ]
      }
    }

    const readTicket = async (ticketId: string) => {
      const ticketForm = {
        _id: ticketId,
        is_read_ticket: true,
      }
      const { updateTicket } = ticketRepositories()
      await updateTicket(ticketForm)
    }

    const tableRowClassName = ({
      row,
      rowIndex,
    }: {
      row: any
      rowIndex: number
    }) => {
      if (row.is_seller) {
        return 'success-row'
      }
      return ''
    }

    const changeDescription = (html: string, dataSet: any) => {
      dataSet.body_text = html
      if (html.length > 3) {
        isDisableAddContent.value = false
      } else {
        isDisableAddContent.value = true
      }
    }

    const requestSellerDialog = ref<InstanceType<typeof RequestSellerDialog>>()
    const onClickRequestSeller = (order: any) => {
      const tmpOrder = { ...order }

      tmpOrder.requestSellerStatus = RequestSellerStatusEnum.updated

      requestSellerDialog.value?.toggle(tmpOrder)
    }

    const onAfterResquestSeller = () => {
      onLoadOrders()
    }

    return {
      onAfterResquestSeller,
      onClickRequestSeller,
      requestSellerDialog,
      getSeller,
      sellerTeam,
      onViewDetailCustom,
      customDialog,
      checkSelectOrderForAction,
      onOpenMessageWarningAction,
      onChangeDisplayCount,
      getCountOrderByStatus,
      countOrderByStatus,
      isShowModalImport,
      isShowTicketListModal,
      isShowTicketDetailModal,
      isAddTicketModal,
      selectdOrderTicketId,
      selectdOrderTicketName,
      content,
      changeDescription,
      isRead,
      tableRowClassName,
      subCategory,
      isDisableAddContent,
      columns,
      submitForm,
      resetForm,
      addComment,
      markTicketResolve,
      state,
      selectedTicket,
      stateImport,
      data,
      dataTicket,
      ticket,
      tickets,
      loading,
      total,
      stateTickets,
      category,
      preferSolution,
      currentPage: ref(1),
      ruleFormRef,
      formSize,
      fileList,
      attacmentList,
      router,
      params,
      ticketParams,
      depositDialog,
      formatterDollars,
      push,
      onChangeDate,
      formatTime,
      onChangeFiles,
      onChangeAttachmentUpload,
      showModalImport,
      onSubmitImport,
      onShowAllTicketsForOrder,
      onShowCreaticketModal,
      handleSizeChange,
      handleCurrentChange,
      changeFilter,
      onImportCsv,
      exportDataOrders,
      handleSelectionChange,
      onSubmitOrder,
      onHoldSelectedOrders,
      onUnHoldSelectedOrders,
      onFilterCallback,
      onCancelSelectedOrders,
      onVerifyAddressSelectedOrders,
      onLoadOrders,
      openDepositDialog,
      changeBalance,
      getOrderStatusColor,
      getAddressStatusColor,
      backToTicketList,
      statusTitleMap,
      requestDesignServiceTitleMap,
      statusListSeller,
      addressTitleMap,
      shortcutsDatetimePicker,
      formatShortDateMonthTime,
      importResultsDialogVisible,
      onGoToImportHistory,
      onDownloadTemplateOrder,
      config,
      countTicket,
      countTotalTicket,
      downloadFile,
      validateEditArtworkCheck,
      detectLineShipOfOrder,
      onClickWarningLineShip,
      onClickContactSupport,
      getTrackingOfOrder,
      copyToClipboardV2,
      isDisplayCountOrderByStatus,
      validateOrderAction,
      getContactSettings,
      validateHold,
      validateUnHold,
      validateSellerCancel,
      validateVerifyAddress,
      validateOrderActionSeller,
      validateSellerHireDesign,
      validateExistsUsingDesignService,
      validateIsNotDropshipOrder,
      validateNoCatalogcodeInOrder,
      holdOrder,
      unHoldOrder,
      cancelOrder,
      verifyAddressOrder,
      confirmExportOrder,
      ADDRESS_STATUS,
      UserTypes,
      userInfo,
      syncedTrackToStoreOrder,
      onSyncedTrackToStoreSelectedOrders,
      onHireDesigns,
      validateUpdateSyncedTrackToStore,
      viewCatalogData,
      onCloseCreaticketModal,
      getFileNameFromUrl,
      getWarningContent,
      getAllStoresSellers,
      RequestSellerStatusEnum,
      RequestSeller,
      reportOrders,
    }
  },
})
